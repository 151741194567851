var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-table-simple',{staticClass:"text-center",style:({
    placeItems: 'center',
    fontSize: _vm.fontSize ? _vm.fontSize + 'px' : null,
  }),attrs:{"lang":"de","striped":"","hover":"","sticky-header":_vm.stickyHeader && _vm.tableHeight,"head-variant":"light","small":"","fixed":_vm.fixed}},[_c('b-thead',{attrs:{"head-variant":"light"}},[_c('b-tr',_vm._l((['Achter', '1', '2', '3', '4', '5', '6', '7', '8']),function(field){return _c('b-th',{key:field},[_vm._v(" "+_vm._s(field)+" ")])}),1)],1),_c('b-tbody',_vm._l((_vm.achter),function(acht,i){return _c('b-tr',{key:i,style:({ height: '1px' })},_vm._l((['achter', '1', '2', '3', '4', '5', '6', '7', '8']),function(label){return _c('b-td',{key:label,style:({ height: 'inherit', verticalAlign: 'middle' })},[(label == 'achter')?_c('span',[_vm._v(" "+_vm._s(acht[label] + 1)+" ")]):(i * 8 + parseInt(label) <= _vm.choreo.counts)?_c('b-button',{staticClass:"p-1 py-2",style:({
            wordBreak: 'break-word',
            hyphens: 'auto',
            minWidth: '50px',
            height: '100%',
            fontSize: _vm.fontSize ? _vm.fontSize + 'px' : null,
            color:
              Math.floor(_vm.count / 8) == i && _vm.count % 8 == label - 1
                ? 'white'
                : null,
          }),attrs:{"disabled":!_vm.interactive,"block":"","variant":Math.floor(_vm.count / 8) == i && _vm.count % 8 == label - 1
              ? 'primary'
              : 'outline-primary'},on:{"click":() => _vm.setCount(i, parseInt(label - 1)),"dblclick":() => _vm.$emit('openCreateHitModal')}},[(acht[label].length > 0)?_c('span',_vm._l((acht[label]),function(hit){return _c('p',{key:hit.name,staticClass:"mb-0"},[_vm._v(" "+_vm._s(hit.name)+" ")])}),0):_c('span',[_vm._v("-")])]):_vm._e()],1)}),1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }