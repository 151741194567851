var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-skeleton-wrapper',{attrs:{"loading":!_vm.currentPositions || _vm.currentPositions.length == 0},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('b-skeleton',{attrs:{"width":_vm.width + 'px',"height":_vm.height + 'px'}})]},proxy:true}])},[_c('svg',{ref:"svgCanvas",staticClass:"svgCanvas",style:({
      border: '1px solid #000000',
      backgroundColor: '#e5e5f7',
      backgroundImage:
        'linear-gradient(to right, #444cf766 5px, #e5e5f744 5px )',
      backgroundSize: _vm.width / 7 + 'px 100%',
      backgroundRepeat: 'repeat',
      '-webkit-touch-callout': 'none',
      '-webkit-user-select': 'none',
      '-khtml-user-select': 'none',
      '-moz-user-select': 'none',
      '-ms-user-select': 'none',
      'user-select': 'none',
    }),attrs:{"height":_vm.height,"width":_vm.width,"mlns":"http://www.w3.org/2000/svg"},on:{"mouseleave":_vm.mouseLeave}},[_vm._l((_vm.currentPositions),function(position){return _c('circle',{key:'c' + position.MemberId,style:({
        opacity:
          _vm.selectedMemberId && position.Member.id == _vm.selectedMemberId.id
            ? 0.7
            : 1,
        cx: (position.x * _vm.width) / 100 + 'px',
        cy: (position.y * _vm.height) / 100 + 'px',
      }),attrs:{"id":'c' + position.MemberId,"r":_vm.dotRadius,"stroke":position.Member.color,"stroke-width":"2","fill":_vm.selectedMemberId && position.Member.id == _vm.selectedMemberId.id
          ? position.Member.color +
            (position.Member.color.length == 4 ? '2' : '22')
          : position.Member.color +
            (position.Member.color.length == 4 ? '5' : '55')},on:{"mousedown":() => _vm.mouseEnter(position.MemberId),"mouseup":_vm.mouseLeave}})}),_vm._l((_vm.currentPositions),function(position){return _c('text',{key:'t' + position.MemberId,style:({
        'pointer-events': 'none',
      }),attrs:{"id":'t' + position.MemberId,"text-anchor":"middle","alignment-baseline":"central","transform":`matrix(1,0,0,1,${(position.x * _vm.width) / 100},${
        (position.y * _vm.height) / 100
      })`}},[_vm._v(" "+_vm._s(position.Member.abbreviation || position.Member.nickname || position.Member.name)+" ")])}),_vm._l((Array(7)),function(_,i){return _c('text',{key:i,style:({
        'pointer-events': 'none',
        fill: '#6c757d !important',
      }),attrs:{"text-anchor":"middle","alignment-baseline":"central","y":15,"x":(_vm.width / 7) * (i + 1) - _vm.width / 7 / 2}},[_vm._v(" "+_vm._s(i + 1)+" ")])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }